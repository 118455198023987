<template>
  <div role="main" class="main">
    <header class="header">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <nav class="navbar navbar-expand-md navbar-dark pl-lg-0">
              <a class="navbar-brand" href="javascript:void(0);">{{
                event ? event.decease_name : "MOURN WITH US"
              }}</a>
              <div class="navbar-list">
                <router-link
                  tag="a"
                  class="text-yellow hover-yellow mr-3 mr-md-5"
                  to="/speaker/profile"
                  >Profile</router-link
                >
              </div>
              <div class="action logout">
                <a
                  href="#"
                  class="text-yellow hover-yellow mr-3"
                  @click="logout"
                  ><i class="fas fa-sign-out-alt"></i>Logout</a
                >
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
    <section class="upload-your-photo with-footer pb-3">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="border-l">
              <p class="text-left">
                UPLOAD YOUR<br />
                PHOTOS
              </p>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <h6 class="text-primary mt-5 mb-2">
                    Please leave a personal message that will display along the
                    photo
                  </h6>
                  <textarea
                    class="form-control bg-white"
                    rows="5"
                    placeholder="Please input a message."
                    v-model="photo.message"
                  ></textarea>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6 pr-lg-0">
                    <div
                      class="
                        photo-area
                        pointer
                        bg-white
                        p-2
                        d-flex
                        align-items-center
                      "
                      @click="$refs.photoFile.click()"
                      v-if="!photo.img"
                    >
                      <h6 class="mb-0">Click here to upload photo</h6>
                    </div>
                    <div
                      class="photo-area pointer"
                      v-if="photo.img"
                      @click="$refs.photoFile.click()"
                    >
                      <img :src="photo.img" class="photo-image" />
                    </div>
                    <input
                      type="file"
                      ref="photoFile"
                      @change="inputFile"
                      accept="image/x-png,image/gif,image/jpeg"
                      style="display: none"
                    />
                  </div>
                  <div class="col-md-6 col-4 pl-lg-0 text-right">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-show="imgList.length > 0">
            <h6 class="text-primary mt-4 mt-lg-0 mb-2">
              Click on photos to enlarge
            </h6>
            <div class="row">
              <div
                class="col-md-3 col-6 text-center mb-2"
                v-for="image in imgList"
                :key="image.id"
              >
                <div
                  class="photo-box pointer"
                  @click="goNext('enlarge', image.photo_id)"
                  style="background-color: white; padding: 5px; width: 120px"
                >
                  <img
                    :src="image.picture_url"
                    class="img-fluid"
                    style="height: 110px; width: 110px"
                  />
                  <!--                  <h6 class="text-yellow">{{image.message}}</h6>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="container">
      <div class="row align-items-end">
        <div class="col-md-9"></div>
        <div class="col-md-3">
          <div class="img-box mt-0 pointer" @click="goBack">
            <img
              :src="require('@/assets/img/return_program.png')"
              class="img-fluid float-left mr-2"
            />
            <label class="text-primary"
              >Return to<br />
              program
            </label>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import { apiBaseUrl } from "../../constants/config";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      photo: {
        message: "",
        img: null,
        imgFile: null,
      },
      imgList: [],
      event: null,
    };
  },
  mounted() {
    this.getImgList();
    getApiManager()
      .post(`${apiBaseUrl}/api/speaker/event/get`)
      .then((response) => {
        let res = response.data;
        if (res.status === true) {
          let detail = res.detail;
          this.event = detail;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    ...mapActions(["signOut"]),
    logout() {
      if (self.OTSession) {
        self.OTSession.disconnect();
      }
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
          live: 0,
          content_type_id: this.content_type_id,
        })
        .then((response) => {
          localStorage.setItem("Live_id", null);
          localStorage.setItem("deceased_name",'')
          this.signOut();
          this.$router.push("/login");
        });
      // window.location.reload();
    },
    goNext(page, img_id = 0) {
      if (page == "enlarge") this.$router.push("/speaker/enlarge/" + img_id);
    },
    goBack() {
      let randomnumber = Math.floor(Math.random() * 10000) + 1;
      this.$router.push("/speaker/parting/"+this.event_id+"?x=" + randomnumber);
    },
    inputFile(event) {
      this.photo.img = event.target.files[0].name;
      this.photo.imgFile = event.target.files[0];
      //set image to the div area
      var reader = new FileReader();
      var ref = this;
      reader.onload = function (e) {
        ref.photo.img = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    submit() {
      if (this.photo.message == "") {
        this.$notify({
          group: "foo",
          type: "warn",
          title: "Warning",
          text: "Please input a message.",
          animation_type: "slide",
        });
        return;
      }
      if (this.photo.imgFile == null) {
        this.$notify({
          group: "foo",
          type: "warn",
          title: "Warning",
          text: "Please select an image.",
          animation_type: "slide",
        });
        return;
      }
      //call api to upload photo
      const formData = new FormData();
      formData.append("message", this.photo.message);
      formData.append("image", this.photo.imgFile);
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/photo/create`, formData)
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            this.imgList = [];
            this.photo.message = "";
            this.photo.img = "";
            this.photo.imgFile = null;
            this.getImgList();
          } else {
            this.$notify({
              group: "foo",
              type: "warn",
              title: "Error",
              text: "Server Error",
              animation_type: "slide",
            });
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getImgList() {
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/photo/list`)
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            this.imgList = res.data;
            for (let i = 0; i < this.imgList.length; i++) {
              this.imgList[i].picture_url =
                `${apiBaseUrl}/` + this.imgList[i].picture_url;
            }
          } else {
            this.$notify({
              group: "foo",
              type: "warn",
              title: "Error",
              text: "Server Error",
              animation_type: "slide",
            });
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {},
  computed: {},
  watch: {},
};
</script>
